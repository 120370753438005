import React from 'react';
import './App.css';

// Import components
import Header from './components/Header/Header';
import HeroSection1 from './components/HeroSection1/HeroSection';
import MovingStrip from './components/MovingStrip/MovingStrip';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import HowToBuy from './components/HowToBuy/HowToBuy';
import Roadmap from './components/RoadMap/Roadmap';
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer';
import Aijockercat from './components/AigcVisitorCounter/Aijockercat';

// Suppress console warnings in production
// This ensures cleaner logs when the app is deployed
if (process.env.NODE_ENV === 'production') {
  console.warn = () => {};
}

function App() {
  return (
    <div className="App">
      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <HeroSection1 />

      {/* Moving Strip Animation */}
      <MovingStrip />

      {/* Promotional Banner */}
      <Banner />

      {/* Roadmap Section */}
      <Roadmap />

      {/* Who We Are Section */}
      <WhoWeAre />

      {/* How to Buy Section */}
      <HowToBuy />

       {/* live visitor count */}
      <Aijockercat />
      {/* Footer Section */}
      <Footer />
    </div>
  );
}

export default App;
