import React from "react";
import VisitorCounter from "./VisitorCounter";
import "./Aijockercat.css";
import ParticleBackground from "../HeroSection1/ParticleSnowEffect";
import facebookIcon from "../../assets/images/webp/cat (3).webp";
const Aijockercat = () => {
  return (
    <div className="live-viewers-section">
      <ParticleBackground />
      
      {/* Left Section: Image */}
      <div className="live-viewers-image">
        <img src={facebookIcon} alt="Facebook Icon" />
      </div>

      {/* Middle Section: Headings and Counters */}
      <div className="live-viewers-content">
        <div className="heading-with-counter">
          <h2 className="heading">Live Viewers</h2>
          <VisitorCounter type="live" />
        </div>

        <div className="heading-with-counter">
          <h2 className="heading">Viewers in 24h</h2>
          <VisitorCounter type="24h" />
        </div>
      </div>
    </div>
  );
};

export default Aijockercat;