import React, { useRef, useEffect } from 'react';
import { WebGLRenderer, Scene, PerspectiveCamera, Points, PointsMaterial, BufferGeometry, Float32BufferAttribute } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const ParticleBackground = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const mount = mountRef.current;

    // Set up scene, camera, and renderer
    const scene = new Scene();
    const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    // Ensure pointer-events don't block scrolling
    renderer.domElement.style.pointerEvents = 'none';

    // Add OrbitControls for interactivity
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Smooth the controls
    controls.dampingFactor = 0.05;

    // Create particles
    const geometry = new BufferGeometry();
    const positions = [];
    const velocities = [];

    const particleCount = 1500; // Increase for more particles
    const maxSpread = 200; // Control particle spread

    for (let i = 0; i < particleCount; i++) {
      const x = (Math.random() - 0.5) * maxSpread; // X position
      const y = (Math.random() - 0.5) * maxSpread; // Y position
      const z = (Math.random() - 0.5) * maxSpread; // Z position
      positions.push(x, y, z);

      const speed = 0.01 + Math.random() * 0.02; // Speed for spreading effect
      velocities.push(x * speed, y * speed, z * speed); // Velocity vector
    }

    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));

    const material = new PointsMaterial({
      color: 0xffffff,
      size: 0.5, // Smaller particles for galaxy-like feel
      transparent: true,
    });

    const stars = new Points(geometry, material);
    scene.add(stars);

    camera.position.z = 50;

    const animate = () => {
      requestAnimationFrame(animate);

      // Update particle positions to spread outward
      const positions = stars.geometry.attributes.position.array;
      for (let i = 0; i < positions.length; i += 3) {
        positions[i] += velocities[i]; // Update X position
        positions[i + 1] += velocities[i + 1]; // Update Y position
        positions[i + 2] += velocities[i + 2]; // Update Z position

        // Reset position when particles move too far out
        if (Math.sqrt(positions[i] ** 2 + positions[i + 1] ** 2 + positions[i + 2] ** 2) > maxSpread) {
          positions[i] = (Math.random() - 0.5) * maxSpread;
          positions[i + 1] = (Math.random() - 0.5) * maxSpread;
          positions[i + 2] = (Math.random() - 0.5) * maxSpread;

          const speed = 0.01 + Math.random() * 0.02;
          velocities[i] = positions[i] * speed;
          velocities[i + 1] = positions[i + 1] * speed;
          velocities[i + 2] = positions[i + 2] * speed;
        }
      }

      stars.geometry.attributes.position.needsUpdate = true;

      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Clean up
    return () => {
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }} />;
};

export default ParticleBackground;
