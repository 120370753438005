// import React from 'react';
// import './MovingStrip.css';
// import lineItem1 from '../../assets/images/cat5-01-01-01iii.png';

// const MovingStrip = () => {
//   return (
//     <div className="moving-strip">
//       <div className="strip-content">
//         <img src={lineItem1} alt="Line Item" className="strip-image" />
//         <span className="strip-text">
//           <span className="ai-text">Ai</span>JokerCat
//         </span>
//         <img src={lineItem1} alt="Line Item" className="strip-image" />
//         <span className="strip-text">
//           <span className="ai-text">Ai</span>JokerCat
//         </span>
//         <img src={lineItem1} alt="Line Item" className="strip-image" />
//         <span className="strip-text">
//           <span className="ai-text">Ai</span>JokerCat
//         </span>
//         <img src={lineItem1} alt="Line Item" className="strip-image" />
//         <span className="strip-text">
//           <span className="ai-text">Ai</span>JokerCat
//         </span>
//         <img src={lineItem1} alt="Line Item" className="strip-image" />
//         <span className="strip-text">
//           <span className="ai-text">Ai</span>JokerCat
//         </span>
//       </div>
//     </div>
//   );
// };

// export default MovingStrip;

import React from 'react';
import './MovingStrip.css';
// import lineItem1 from '../../assets/images/cat5-01-01-01iii.png';

import lineItem1 from "../../assets/images/webp/cat5-01-01-01iii.webp";

const MovingStrip = () => {
  const stripItems = [
    { id: 1, text: "JokerCat", image: lineItem1 },
    { id: 2, text: "JokerCat", image: lineItem1 },
    { id: 3, text: "JokerCat", image: lineItem1 },
    { id: 4, text: "JokerCat", image: lineItem1 },
    { id: 5, text: "JokerCat", image: lineItem1 },
  ];

  return (
    <div className="moving-strip">
      <div className="strip-content">
        {stripItems.map((item) => (
          <React.Fragment key={`strip-item-${item.id}`}>
            <img src={item.image} alt="Line Item" className="strip-image" />
            <span className="strip-text">
              <span className="ai-text">Ai</span>{item.text}
            </span>
          </React.Fragment>
        ))}
        {/* Duplicate content for seamless scrolling */}
        {stripItems.map((item) => (
          <React.Fragment key={`strip-item-duplicate-${item.id}`}>
            <img src={item.image} alt="Line Item" className="strip-image" />
            <span className="strip-text">
              <span className="ai-text">Ai</span>{item.text}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MovingStrip;
