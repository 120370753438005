import React, { useState, useEffect } from "react";
import apiClient from "../../config/apiConfig"; // Ensure you have apiClient properly configured
import "./HowToBuy.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import image6 from "../../assets/images/HTB-CAT-image.png";
import Cat from "../../assets/images/catmob (1).png";
import Earth from "../../assets/images/Earth.png";
import Sun from "../../assets/images/sun-1.png";
import Mars from "../../assets/images/r4 (1).png";
import Jupiter from "../../assets/images/r5 (1).png";
import Venus from "../../assets/images/r2 (1).png";

const HowToBuy = () => {
   // manage state variables
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [url, setUrl] = useState("");
  const [newCopied, setNewCopied] = useState(false);
// fetch token when component mounts
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await apiClient.get("/api/get-token");
        if (response.status === 200) {
          setUrl(response.data.token);
        } else {
          console.error("Failed to fetch the token");
        }      } catch (error) {
        console.error("Error fetching the token:", error);
      }
    };
    fetchToken();
  }, []);
 // function to handle URL copy
  const handleCopy = () => {
    if (url) {
      navigator.clipboard.writeText(url);
      setNewCopied(true);
      setTimeout(() => setNewCopied(false), 2000); // Reset after 2 seconds
    }
  };
 // handle device visibility
  useEffect(() => {
    const handleVisibility = () => {
      const section = document.querySelector(".howtobuy-section");
      if (section) {
        const rect = section.getBoundingClientRect();
        setIsVisible(rect.top < window.innerHeight && rect.bottom > 0);
      }
    };
  //if screen is less than 500px, set mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };
//event listeners for scroll and resize
    window.addEventListener("scroll", handleVisibility);
    window.addEventListener("resize", handleResize);

    handleVisibility();
    handleResize();
  // cleanup function to remove event listeners
    return () => {
      window.removeEventListener("scroll", handleVisibility);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
// Card data for each step
  const steps = [
    {
      text: "Download and install a Solana-compatible wallet like Phantom or Solflare.",
      iconImage: Venus,
      isReversed: false,
      className: "venus-shadow",
    },
    {
      text: "Purchase $SOL from a reputable exchange like Coinbase, Binance, or Kraken.",
      iconImage: Jupiter,
      isReversed: true,
      className: "jupiter-shadow",
    },
    {
      text: "Transfer $SOL to your wallet.",
      iconImage: Earth,
      isReversed: false,
      className: "earth-shadow",
    },
    {
      text: "Connect your wallet to a DEX like Radium or a trading tool like Jupiter.",
      iconImage: Mars,
      isReversed: true,
      className: "mars-shadow",
    },
    {
      text: `Swap $SOL for $AIJokerCat with CA: ${url}`,
      iconImage: Sun,
      isReversed: false,
      isCopyable: true,
      className: "sun-shadow",
    },
  ];
 

  return (
    <div className="howtobuy-section" id="how-to-buy">
      <div className="howtobuy-bg-image">
        <div className={`howtobuy-inner-image ${isVisible ? "howtobuy-visible" : ""}`}>
            {/* desktop View */}
          <div className="howtobuy-image desktop">
            <div className="howtobuy-image-header">
              HOW <div className="highlighted-text">TO</div> BUY?
            </div>
            <div className="card">
              <div className="steps">
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`step ${step.isReversed ? "reverse" : ""}`}
                    style={{ position: "relative" }}
                  >
                    <img src={step.iconImage} alt={`Step ${index + 1}`}  className={step.className}/>
                    <div>
                      <div style={{ textAlign: "left", fontFamily: "'Satoshi', sans-serif", fontSize: "18px", wordBreak: "break-word", padding: "20px" }}>
                        {step.text}
                      </div>
                    </div>
                     {/* show copy icon thats make step copyable */}
                    {step.isCopyable && (
                      <div style={{ position: "absolute", top: "10px", right: "25px", zIndex: 999 }}>
                        <ContentCopyIcon
                          onClick={handleCopy}
                          style={{ cursor: "pointer", color: newCopied ? "green" : "gray" }}
                          titleAccess="Copy to clipboard"
                        />
                        {newCopied && <span style={{ color: "green", marginLeft: "8px" }}>Copied!</span>}
                          {/* Show copied message */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div>
                <img src={image6} alt="Cat Character" className="cat-image" />
              </div>
            </div>
          </div>
          {/* Mobile View */}
          <div className="mobile">
            <div className="glassContainer">
              <div className="stepsMobile">
                <div className="howtobuy-image-header-mobile mobile">
                  HOW <span className="highlighted-text">TO</span> BUY?
                </div>
                  {/* render each card in mobile view */}
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`stepMobile ${step.isReversed ? "reverse" : ""}`}
                    style={{ position: "relative" }}
                  >
                    <img src={step.iconImage} className={step.className} alt={`Step ${index + 1}`} />
                    <div>
                      <div className="how-to-buy-content-text-mobile">
                        {step.text}
                         {/* copy icons make copyable */}
                        {step.isCopyable && (
                          <span>
                            <ContentCopyIcon
                              onClick={handleCopy}
                              className="copy-icon-mobile"
                              style={{ cursor: "pointer", color: newCopied ? "green" : "gray" }}
                              titleAccess="Copy to clipboard"
                            />
                            {newCopied && <span style={{ color: "green", marginLeft: "8px" }}>Copied!</span>}
                               {/* Show copied message */}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <img src={Cat} alt="Mascot" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
