
import React from 'react';
import './Footer.css';
// import logo from '../../assets/images/footer logo.png';
// import twitterIcon from '../../assets/images/Twitter.png';
// import linkedinIcon from '../../assets/images/tg.png';
// import facebookIcon from '../../assets/images/dex tool.png';
//webp
import logo from "../../assets/images/webp/footer logo.webp";
import twitterIcon from "../../assets/images/webp/Twitter.webp";
import linkedinIcon from "../../assets/images/webp/tg.webp";
// import facebookIcon from "../../assets/images/webp/dex tool.webp";
import facebookIcon from "../../assets/images/fb-icon.png";
const Footer = ({
  supportEmail = "support@Aijokercat.xyz",
  year = "2024",
  companyName = "aijokercat",
}) => {
  return (
    <footer className="aijokercat-footer">
      <div className="aijokercat-footer-content">
        {/* Logo Section */}
        <div className="aijokercat-logo-container">
          <img src={logo} alt="Logo" className="aijokercat-logo" />
        </div>

        {/* Quick Links Section */}
        <div className="aijokercat-quick-links-section">
          <h3 className="aijokercat-quick-links-title">Quick Links</h3>
          <div className="aijokercat-links-grid">
            <div className="aijokercat-links-column">
              <a href="#about" className="aijokercat-link">About</a>
              <a href="#roadmap" className="aijokercat-link" data-accent="red">RoadMap</a>
            </div>
            <div className="aijokercat-links-column">
              <a href="#dextools" className="aijokercat-link">BuyNow</a>
              <a href="#how-to-buy" className="aijokercat-link" data-accent="white">How To buy ?</a>
            </div>
          </div>
        </div>

        {/* Contact Section */}
        <div className="aijokercat-contact-section">
          <a href={`mailto:${supportEmail}`} className="aijokercat-support-email">
            <span>support@</span><span className="aijokercat-highlight">Aijokercat.xyz</span>
          </a>
        </div>

        {/* Divider */}
        <div className="aijokercat-divider"></div>

        {/* Footer Bottom Section */}
        <div className="aijokercat-footer-bottom">
          {/* Social Icons */}
          
          <div className="aijokercat-social-icons">
            <a href="https://t.me/aijokercat" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt="LinkedIn" className="aijokercat-social-icon" />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" className="aijokercat-social-icon" />
            </a>
            <a href="https://x.com/AIJokerCat" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" className="aijokercat-social-icon" />
            </a>
          </div>       
          

          {/* Company Info */}
          {/* <div className="aijokercat-info-container">
            <p className="aijokercat-company-info">
              A product of <span className="aijokercat-highlight">Cognizn.</span>
            </p>
          </div> */}

          {/* Copyright Info */}
          <div className="aijokercat-copyright-container">
            <p className="aijokercat-copyright">
              © {year} {companyName}. All rights <span className="aijokercat-highlight">reserved</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
