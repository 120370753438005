import React from "react";
import "./WhoWeAre.css";
import ParticleBackground from "../HeroSection1/ParticleSnowEffect";
// import headingImage from "../../assets/images/text (2).png";
// import bottomImage from "../../assets/images/Group 22 (3).png";
// import bottomImageWithoutBorder from "../../assets/images/globe and cat.png";

import headingImage from "../../assets/images/webp/text (2).webp";
import bottomImage from "../../assets/images/webp/Group 22 (3).webp";
import bottomImageWithoutBorder from "../../assets/images/webp/globe and cat.webp";

const WhoWeAre = () => {
  return (
    <>
      <section className="who-we-are-section" id="about">
        <ParticleBackground />
        <div className="who-we-are-bg">
          {/* Heading Image */}
          <img
            src={headingImage}
            alt="Who We Are Heading"
            className="who-we-are-heading-img"
          />

          {/* Bottom Images */}
          <img
            src={bottomImage}
            alt="Decorative Bottom Image"
            className="who-we-are-bottom-img"
            aria-hidden="true"
          />
          <img
            src={bottomImageWithoutBorder}
            alt="Globe and Cat Illustration"
            className="who-we-are-bottom-img-border"
            aria-hidden="true"
          />

          {/* Text Content */}
          <article className="text-overlay image-frame">
            <p>
              A cryptocurrency like no other, JokerCat Coin combines cutting-edge
              blockchain technology with a playful vibe.
            </p>
            <p>
              It's fast, secure, eco-friendly, and perfect for anyone — whether
              you're a crypto pro or just starting out.
            </p>
          </article>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
