import React, { useEffect, useState } from "react";
import apiClient from "../../config/apiConfig";

const VisitorCounter = ({ type }) => {
  const [count, setCount] = useState(null);

  useEffect(() => {
    const fetchVisitorCount = async () => {
      try {
        const response = await apiClient.put("/update_count");
        if (response.data.success) {
          setCount(
            type === "live" 
              ? response.data.total_count
              : response.data.current_count 
          );
          console.log(`${type} visitor count fetched successfully: `, count);
        } else {
          console.error("Failed to fetch visitor count.");
        }
      } catch (error) {
        console.error("Error fetching visitor count:", error);
      }
    };

    fetchVisitorCount();
  }, [type]);

  return (
    <div className="counter-container">
      {count !== null ? (
        <div>
          <span>{count}</span>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default VisitorCounter;
