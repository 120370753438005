// Roadmap.js
import React, { useEffect, useRef } from "react";
import "./Roadmap.css";
// import headingImage from "../../assets/images/bgnewwww (2).png";
// import catImage from "../../assets/images/cat 4.0.png";

import headingImage from "../../assets/images/webp/bgnewwww (2).webp";
import catImage from "../../assets/images/webp/cat 4.0.webp";
import RoadmapCard from "./RoadmapCard";

const Roadmap = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const heading = section.querySelector(".roadmap-heading-image");
    const steps = section.querySelectorAll(".roadmap-step");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            heading.classList.add("visible");
            steps.forEach((step) => step.classList.add("visible"));
          }
        });
      },
      { threshold: 0.3 }
    );

    observer.observe(section);

    return () => {
      observer.disconnect();
    };
  }, []);

  const roadmapData = [
    {
      title: "PHASE 1",
      description: (
        <>
          <p
            style={{
              color: "white",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            <a
              href="https://x.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#00FF00",
                textDecoration: "underline",
                fontFamily: "Comic Sans MS",
              }}
            >
              X.com
            </a>{" "}
            mention interaction via voice.
          </p>
          <p
            style={{
              color: "#00FF00",
              fontWeight: "bold",
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            We are the first in the universe to do this!
          </p>
          <p
            style={{
              color: "white",
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            Enhance JokerCat personality by learning from audience engagement
          </p>
          <p
            style={{
              color: "white",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            Enhance voice quality
          </p>
        </>
      ),
    },
    {
      title: "PHASE 2",
      description: (
        <>
          <div
            style={{
              color: "white",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            Humorous overview of the latest headlines in the world{" "}
            <span style={{ color: "#00FF00" }}>
              (no more negative news! We're tired of it!)
            </span>
            <span> Think *Daily Show*.</span>
          </div>
          <div
            style={{
              color: "#00FF00",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            ANOTHER First of its kind <span>AI interaction </span>
          </div>
          <p
            style={{
              color: "white",
              fontFamily: "Comic Sans MS",
              textAlign: "left",
            }}
          >
            enhancements (To be revealed soon. We are already working{" "}
          </p>
          <p
            style={{
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            on these) -{" "}
            <span
              style={{
                color: "#00FF00",
                fontFamily: "Comic Sans MS",
                textAlign: "left",
              }}
            >
              MOONSHOT!
            </span>
          </p>
        </>
      ),
    },
    {
      title: "PHASE 3",
      description: (
        <>
          <p
            style={{
              color: "white",
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            Instagram AR Filter. Transform into your favorite Joker!
          </p>
          <p
            style={{
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
              color: "white",
            }}
          >
            Enhance AI engagement and make interactions more fun, personalized,
            and{" "}
          </p>
          <p
            style={{
              color: "#00FF00",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            ENTERTAINING!
          </p>
        </>
      ),
    },
    {
      title: "PHASE 4",
      description: (
        <>
          <div
            style={{
              marginBottom: "16px",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            <span style={{ color: "white" }}>Collaborations with </span>
            <span style={{ color: "#00FF00" }}>LINED UP</span>
            <div style={{ color: "white" }}>influencers</div>
          </div>
          <p
            style={{
              color: "white",
              lineHeight: "1.6",
              fontSize: "16px",
              textAlign: "left",
              fontFamily: "Comic Sans MS",
            }}
          >
            Sponsorships from like-minded{" "}
            <span style={{ color: "#00FF00" }}>brands</span>
          </p>
        </>
      ),
    },
  ];
  

  return (
    <div className="roadmap-how-to-buy-section" ref={sectionRef} id="roadmap">
       {/* Section Heading Image */}
      <img src={headingImage} alt="How To Buy" className="roadmap-heading-image" />
       {/* Centered Cat Image */}
      <div className="roadmap-center-cat">
        <img src={catImage} alt="Cat" className="roadmap-cat-image" />
      </div>
        {/* Roadmap Steps Container */}
      <div className="roadmap-steps-container">
        {roadmapData.map((data, index) => (
          <div
            key={index}
            className={`roadmap-step roadmap-step${index + 1} ${
              index % 2 === 0 ? "roadmap-left" : "roadmap-right"
            }`}
          >
            {/* Reusable RoadmapCard Component */}
            <RoadmapCard title={data.title} description={data.description} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
