import React from 'react';
import ParticleBackground from './ParticleSnowEffect';
import './HeroSection.css';

// Import assets
// import heroImage from '../../assets/images/cat canvas small.png';
// import twitterIcon from '../../assets/images/twww.png';
// import telegramIcon from '../../assets/images/tgg.png';
// import buyNowIcon from '../../assets/images/buy 5.0.png';
// import contactUsIcon from '../../assets/images/ett.png';

import twitterIcon from "../../assets/images/webp/twww.webp";
import telegramIcon from "../../assets/images/webp/tgg.webp";
import buyNowIcon from "../../assets/images/webp/buy 5.0.webp";
import contactUsIcon from "../../assets/images/webp/ett.webp";
import videoSource from '../../assets/images/cat-video.mp4';

const HeroSection = () => {
  return (
    <div className="herosection1-hero-section">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="herosection1-background-video"
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Particle Effect */}
      <ParticleBackground />

      {/* Hero Section Content */}
      <div className="herosection1-hero-content">
        <div className="herosection1-center-container">
          {/* Left Buttons */}
          <div className="herosection1-buttons herosection1-buttons-left">
            <HeroButton
              href="https://buy-now-link.com"
              iconSrc={contactUsIcon}
              altText="Buy Now"
            />
            <HeroButton
              href="https://x.com/AIJokerCat"
              iconSrc={twitterIcon}
              altText="Twitter"
            />
          </div>

          {/* Centered Image */}
          {/* Uncomment if needed */}
          {/* <img src={heroImage} alt="Hero" className="herosection1-hero-image" /> */}

          {/* Right Buttons */}
          <div className="herosection1-buttons herosection1-buttons-right">
            <HeroButton
              href="https://t.me/aijokercat"
              iconSrc={telegramIcon}
              altText="Telegram"
            />
            <HeroButton
              href="https://contact-us-link.com"
              iconSrc={buyNowIcon}
              altText="Contact Us"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * HeroButton Component - Renders an image button link.
 *
 * @param {string} href - URL to navigate.
 * @param {string} iconSrc - Path to button icon.
 * @param {string} altText - Alternative text for the icon.
 */
const HeroButton = ({ href, iconSrc, altText }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <img src={iconSrc} alt={altText} className="herosection1-button-icon"  loading="lazy"/>
  </a>
);

export default HeroSection;
